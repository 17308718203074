export enum ELocalStorageKeys {
  SELECTABLE_ITEMS = "selectable",
  SLIDE_ID_TO_ID_MAP = "slideIdToIdMap",
  CATEGORY_ID_TO_ID_MAP = "categoryIdToIdMap",
}

export const getSelectableFromLocalStorage = (): number[] =>
  JSON.parse(
    localStorage.getItem(ELocalStorageKeys.SELECTABLE_ITEMS) as string,
  ) || [];

export const setSelectableToLocalStorage = (items: number[]) => {
  localStorage.setItem(
    ELocalStorageKeys.SELECTABLE_ITEMS,
    JSON.stringify(items),
  );
};

export const getSlideIdToIdMapFromLocalStorage = (): Record<number, number> =>
  JSON.parse(
    localStorage.getItem(ELocalStorageKeys.SLIDE_ID_TO_ID_MAP) as string,
  ) || {};

export const setSlideIdToIdMapToLocalStorage = (
  items: Record<number, number>,
) => {
  localStorage.setItem(
    ELocalStorageKeys.SLIDE_ID_TO_ID_MAP,
    JSON.stringify(items),
  );
};

export const getCategoryIdToIdMapFromLocalStorage = (): Record<
  number,
  number[]
> =>
  JSON.parse(
    localStorage.getItem(ELocalStorageKeys.CATEGORY_ID_TO_ID_MAP) as string,
  ) || {};

export const setCategoryIdToIdMapToLocalStorage = (
  items: Record<number, number[]>,
) => {
  localStorage.setItem(
    ELocalStorageKeys.CATEGORY_ID_TO_ID_MAP,
    JSON.stringify(items),
  );
};

export enum EIconNames {
  ARROW_RIGHT = "arrowRight",
  ARROW_UP = "arrowUp",
  CHECKBOX = "checkbox",
  FLAG = "flag",
  FLAG_FILLED = "flagFilled",
  BIN = "bin",
  CROSS = "cross",
  CHAIN = "chain",
  EXCLAMATION = "exclamation",
  SPINNER = "loader",
  UPLOAD = "upload",
  LOGOUT = "logout",
  PROFILE = "profile",
  UPLOAD_SIGN = "upload_sign",
  PPTX = "pptx",
  STAR = "star",
  LOCK = "lock",
  STAR_OFF = "star_off",
  ARROW_LEFT = "arrowLeft",
  INFO_CIRCLE = "info_circle",
  EDIT = "edit",
  TRUE_BADGE = "true_badge",
  FALSE_BADGE = "false_badge",
  COMMON_PLUS = "common_plus",
  COMMON_MINUS = "common_minus",
  SEARCH = "search",
}

export enum ESizes {
  XL = "xl",
  L = "l",
  M = "m",
  S = "s",
  XS = "xs",
}

export enum EButtonTypes {
  GHOST = "ghost",
  BORDER = "border",
  FILL = "fill",
}

export enum EButtonStyles {
  PRIMARY = "primary",
  SECONDARY = "secondary",
  LABEL = "label",
}

export enum EProfileMenuItems {
  PROFILE = "profile",
  UPLOADED = "uploaded",
}

export enum EPermissions {
  UPLOAD,
  VIEW_USER_RATING,
  REVIEWED_FILTER,
  DELETE_PRESENTATION,
  EXPORT,
}

export enum ECommonEvents {
  RESET_PERMISSIONS = "reset_permissions",
}

export enum EMetaDataType {
  BOOLEAN = "upw.mbool",
  STRING = "upw.mstr",
  DATE = "upw.mdate",
  ENUM = "upw.menum",
  FLOAT = "upw.mfloat",
  INTEGER = "upw.mint",
}

export enum EMetaDataTypeEditable {
  BOOLEAN = "pw.mbool",
  STRING = "pw.mstr",
  DATE = "pw.mdate",
  ENUM = "pw.menum",
  FLOAT = "pw.mfloat",
  INTEGER = "pw.mint",
}

export enum EMetaDataPhysicalType {
  STRING = 1,
  INTEGER = 2,
  FLOAT = 3,
  ENUM = 4,
  DATE = 5,
  BOOLEAN = 6,
}

export enum EElementColors {
  DEFAULT = "default",
  SUCCESS = "success",
  WARNING = "warning",
  DANGER = "danger",
}

export enum EMainGroupType {
  CATEGORY = 1,
  PRESENTATION = 2,
}

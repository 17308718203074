import {
  createContext,
  useEffect,
  useState,
  type ReactNode,
  type SetStateAction,
  type Dispatch,
} from "react";
import {
  getSlideIdToIdMapFromLocalStorage,
  getSelectableFromLocalStorage,
  setSlideIdToIdMapToLocalStorage,
  setSelectableToLocalStorage,
  getCategoryIdToIdMapFromLocalStorage,
  setCategoryIdToIdMapToLocalStorage,
} from "common/utils/localStorage";
import {logError} from "common/utils/common";
import {getExportCategory} from "../api/services/Export/Export";
import {useAuthContext} from "common/hooks/useAuthContext";

interface IStorageProvider {
  children: ReactNode;
}

interface IStorageContext {
  selectable: number[] | null;
  setSelectable: Dispatch<SetStateAction<number[]>>;
  slideIdToIdMap: Record<number, number> | null;
  setSlideIdToIdMap: Dispatch<SetStateAction<Record<number, number>>>;
  onSelectableChange: (id: number, slideId: number, cId: number) => void;
  cleanAll: () => void;
  getSlideIdFromId: (id: number) => string | undefined;
  categoryIdToIdMap: Record<string, number[]> | null;
  getCategoryIdFromId: (id: number) => string | undefined;
  removeCategory: (categoryId: number) => void;
  addCategory: (categoryId: number) => void;
}

interface IAddStates {
  selectable: number[];
  slideIdToIdMap: Record<number, number>;
  categoryIdToIdMap: Record<number, number[]>;
}

const StorageContext = createContext<IStorageContext | null>(null);
export const StorageContextProvider = ({children}: IStorageProvider) => {
  const {permissions} = useAuthContext();
  const [selectable, setSelectable] = useState<number[]>(
    getSelectableFromLocalStorage,
  );
  const [slideIdToIdMap, setSlideIdToIdMap] = useState<Record<number, number>>(
    getSlideIdToIdMapFromLocalStorage,
  );
  const [categoryIdToIdMap, setCategoryIdToIdMap] = useState<
    Record<number, number[]>
  >(getCategoryIdToIdMapFromLocalStorage);

  useEffect(() => {
    setSelectableToLocalStorage(selectable);
  }, [selectable]);
  useEffect(() => {
    setSlideIdToIdMapToLocalStorage(slideIdToIdMap);
  }, [slideIdToIdMap]);
  useEffect(() => {
    if (!permissions?.canExport && selectable.length) {
      cleanAll();
    }
  }, [permissions]);
  useEffect(() => {
    setCategoryIdToIdMapToLocalStorage(categoryIdToIdMap);
  }, [categoryIdToIdMap]);

  const getSlideIdFromId = (id: number) =>
    slideIdToIdMap &&
    Object.keys(slideIdToIdMap).find(key => slideIdToIdMap[+key] === id);

  const removeKey = (key: string, {[key]: _, ...rest}) => rest;

  const removeKeys = (removedKeys: string[], obj: any) => {
    let newState = {...obj};
    removedKeys.forEach(removedKey => {
      newState = removeKey(removedKey, newState);
    });
    return newState;
  };

  const onSelectableChange = (
    id: number,
    slideId: number,
    categoryId: number,
  ) => {
    if (id && slideId && categoryId) {
      const originalId = slideIdToIdMap[slideId];
      if (originalId) {
        const removeKey = (key: string, {[key]: _, ...rest}) => rest;

        setSelectable(prev =>
          prev.filter(selectedId => selectedId !== originalId),
        );
        setSlideIdToIdMap(prev => removeKey(String(slideId), prev));
        setCategoryIdToIdMap(prev => ({
          ...prev,
          [categoryId]: [
            ...(prev[categoryId]
              ? prev[categoryId].filter(selectedId => selectedId !== id)
              : []),
          ],
        }));
      } else {
        setSelectable(selectable => [...selectable, id]);
        setSlideIdToIdMap(prev => ({
          ...prev,
          [slideId]: id,
        }));
        setCategoryIdToIdMap({
          ...categoryIdToIdMap,
          [categoryId]: [...(categoryIdToIdMap[categoryId] ?? []), id],
        });
      }
    }
  };

  const cleanAll = () => {
    setSelectable([]);
    setSlideIdToIdMap({});
    setCategoryIdToIdMap({});
  };

  const getCategoryIdFromId = (id: number) =>
    categoryIdToIdMap &&
    Object.keys(categoryIdToIdMap).find(item =>
      categoryIdToIdMap[+item]?.includes(id),
    );

  const removeCategory = (categoryId: number) => {
    const needRemoveIds = categoryIdToIdMap[categoryId];
    const needRemoveSlideIds = Object.keys(slideIdToIdMap).filter(key =>
      needRemoveIds?.includes(slideIdToIdMap[+key] as number),
    );
    setCategoryIdToIdMap(prev => removeKey(categoryId.toString(), prev));
    setSlideIdToIdMap(prev => removeKeys(needRemoveSlideIds, prev));
    setSelectable(prev =>
      prev.filter(selectedId => !needRemoveIds?.includes(selectedId)),
    );
  };

  const addCategory = (categoryId: number) => {
    if (categoryId) {
      getExportCategory({id: categoryId})
        .then(response => {
          if (response.data.length) {
            const addStates: IAddStates = {
              selectable: [],
              slideIdToIdMap: {},
              categoryIdToIdMap: {},
            };
            response.data.forEach(item => {
              addStates.selectable.push(item.id);
              addStates.slideIdToIdMap[item.slideId] = item.id;
              addStates.categoryIdToIdMap[categoryId] = [
                ...(addStates.categoryIdToIdMap[categoryId] ?? []),
                item.id,
              ];
            });
            updateStates(addStates);
          }
        })
        .catch(error => {
          logError(error);
        });
    }
  };

  const updateStates = (addStates: IAddStates) => {
    setSelectable(prev => [...prev, ...addStates.selectable]);
    setSlideIdToIdMap(prev => ({...prev, ...addStates.slideIdToIdMap}));
    setCategoryIdToIdMap(prev => ({...prev, ...addStates.categoryIdToIdMap}));
  };

  return (
    <StorageContext.Provider
      value={{
        slideIdToIdMap,
        selectable,
        setSlideIdToIdMap,
        setSelectable,
        onSelectableChange,
        cleanAll,
        getSlideIdFromId,
        categoryIdToIdMap,
        getCategoryIdFromId,
        addCategory,
        removeCategory,
      }}>
      {children}
    </StorageContext.Provider>
  );
};

export default StorageContext;

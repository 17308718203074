import {Component, type JSX, Suspense} from "react";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {ruRU} from "@mui/x-date-pickers/locales";
import "dayjs/locale/ru";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {AuthContextProvider} from "../../provider/AuthProvider";
import {ThemeContextProvider} from "../../provider/ThemeProvider";
import Routes from "./routes";
import "assets/less/styles.less";
import {StorageContextProvider} from "../../provider/StorageProvider";

export class App extends Component {
  render(): JSX.Element {
    return (
      <ThemeContextProvider>
        <AuthContextProvider>
          <StorageContextProvider>
            <LocalizationProvider
              adapterLocale="ru"
              localeText={
                ruRU.components.MuiLocalizationProvider.defaultProps.localeText
              }
              dateAdapter={AdapterDayjs}>
              <Suspense>
                <Routes />
              </Suspense>
            </LocalizationProvider>
          </StorageContextProvider>
        </AuthContextProvider>
      </ThemeContextProvider>
    );
  }
}

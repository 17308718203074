import {type MouseEvent} from "react";

/** Проверяет произошел ли клик внутри компонента
 *
 * @param {MouseEvent} e Событие клика.
 * @param {HTMLElement} element Элемент, который необходимо проверить.
 */
export function checkIsClickInside(e: MouseEvent, element: HTMLElement) {
  const r = element.getBoundingClientRect();

  return (
    e.clientX > r.left &&
    e.clientX < r.right &&
    e.clientY > r.top &&
    e.clientY < r.bottom
  );
}

export function getKiloBytesFromMegaBytes(value: number) {
  return value * 1024;
}

export function getKiloBytesFromBytes(value: number) {
  return value / 1024;
}

export function logError(error: unknown) {
  const result = error instanceof Error ? error.message : String(error);

  console.log("ERROR", result);
}
